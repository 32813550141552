@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"

































































.question-navigation-item
  +flex-center
  background: $color-white
  border: 1px solid $color-ink-lightest
  border-radius: $border-radius-m
  cursor: pointer
  height: $size-l
  position: relative
  width: $size-l
  +transition($speed-fast)

  &:hover
    background: transparentize($color-ink-lightest, 0.5)

  &:focus
    outline: none
    box-shadow: 0 0 0 2px $color-primary

  &:disabled
    opacity: 0.75
    cursor: default

  &.--active
    border-color: $color-primary
    background: $color-primary

    &:focus
      box-shadow: 0 0 0 2px $color-primary-dark

  &__number
    color: $color-ink-light
    font-weight: $font-weight-bold

    .--active &
      color: $color-white

  &__icon
    +flex-center
    border: 1px solid $color-white
    border-radius: $size-s
    position: absolute
    bottom: -6px
    right: -6px
    width: $size-s
    height: $size-s

    ::v-deep i
      +element-icon-size(10px)

    ::v-deep svg
      stroke: $color-white
      stroke-width: 4

    .--correct &
      background: $color-success

    .--wrong &
      background: $color-danger

