@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"
























































.empty-state
  display: flex
  align-items: center
  flex-direction: column
  padding: 24px
  text-align: center

  &__icon
    height: 250px
    padding: 12px 0

    +mq_s
      height: 200px

  h4
    margin-top: 15px
    font-size: 24px

  &__text
    padding: 12px 0
    margin: 10px 0

    p
      font-size: 16px
      color: $color-ink-light
