@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";













































































.question-navigation {
  background: linear-gradient(180deg, rgba(0,0,0, 0.1) 0, rgba(0,0,0,0) 3px), linear-gradient(0deg, rgba(0,0,0, 0.15) 0, #F7F7F7 2px);
  width: 100%;
  padding: 0 $size-xs;
  overflow-x: auto;

  &__wrapper {
    @include flex-center-start;
    padding: $size-xs 0;
    margin: 0 auto;
    min-width: 1032px;
    max-width: max-content;

    @include mq_xl {
      min-width: calc(100% - 64px);
    }

    @include mq_l {
      min-width: calc(100% - 64px);
    }

    @include mq_m {
      min-width: calc(100% - 48px);
    }

    @include mq_s {
      min-width: calc(100% - 16px);
    }

    @include mq_xs {
      min-width: calc(100% - 16px);
    }

    ::v-deep .question-navigation-item {
      @include space-inline($size-xs);
      flex-shrink: 0;
    }
  }
}
