@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";











































































.search-proposed-assignment {
  background: $color-white;
  width: 100%;

  &__state {
    min-height: calc(100vh - #{$menu-height});
    @include flex-center;
  }
}

::v-deep .question-bar__header {
  &__title {
      color: $color-white;

      span {
        color: $color-white;
        opacity: 0.8
      }
    }

    .sas-button {
       color: $color-white;
    }
}
