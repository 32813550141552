@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"



























































// --------------------------------------------------------------
// Variables
// --------------------------------------------------------------
$spinner-size-l: $size-xxl
$spinner-size-m: $size-l
$spinner-size-s: $size-s

// --------------------------------------------------------------
// Base
// --------------------------------------------------------------
.sas-spinner
  +transition($speed-fast)
  transform-origin: center
  animation: rotate 2s linear infinite

  // --------------------------------------------------------------
  // Sizes
  // --------------------------------------------------------------
  &.--large
    width: $spinner-size-l
  &.--medium
    width: $spinner-size-m
  &.--small
    width: $spinner-size-s
    stroke-width: 4

  // --------------------------------------------------------------
  // Circle
  // --------------------------------------------------------------
  circle
    fill: none
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
    stroke-linecap: round
    animation: dash 1.5s ease-in-out infinite
    cx: 50
    cy: 50
    r: 20

// --------------------------------------------------------------
// Animation
// --------------------------------------------------------------
@keyframes rotate
  100%
    transform: rotate(360deg)

@keyframes dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0

  50%
    stroke-dasharray: 90, 200
    stroke-dashoffset: -35px

  100%
    stroke-dashoffset: -125px
