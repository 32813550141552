@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";




























































































































.question-bar {
  &__header {
    padding: $size-s 0;

    &__wrapper {
      @include flex-space-between;
    }

    &__title {
      span {
        color: $color-ink-light;
      }

      .sas-skeleton-loader:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &.--light &__header {
    &__title {
      color: $color-white;

      span {
        color: $color-white;
        opacity: 0.8
      }
    }

    .sas-button {
      color: $color-white;
    }
  }
}
